<template>
    <zw-sidebar @shown="shown">
        <template v-slot:sidebar-title>
            <h2>{{ $t('offering.position.title') }}&nbsp;</h2>
            <b-row v-if="form.articleId && form.article">
                <b-col cols="12">
                    <b-button
                        @click="openArticle(form.articleId)"
                        v-if="$root.hasAccess('articles','update')"
                        size="sm"
                        variant="info"
                        class="mr-2"
                        :title="$t('common.title.edit')"
                        style="margin-top: -10px;"
                    >
                        {{ form.article.sku }} &nbsp;
                        <font-awesome-icon icon="edit"/>
                    </b-button>
                </b-col>
            </b-row>
        </template>
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="8">
                        <b-card :header="$t('offering.position.group.position')">
                            <b-card-text>
                                <b-row>
                                    <b-col cols="12">
                                        <zw-input-group name="name"
                                                        v-model="form.name"
                                                        :label-prefix="labelPrefix"
                                        ></zw-input-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <zw-ckeditor v-model="form.description"></zw-ckeditor>
                                    </b-col>
                                    <b-col cols="6">
                                        <zw-select-group v-model="form.type"
                                                         :options="getPositionTypes()"
                                                         :label-prefix="labelPrefix"
                                                         name="type"
                                        ></zw-select-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <zw-switch-group v-model="form.add_tarif_weight"
                                                         name="add_tarif_weight"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <zw-input-group name="deliveryTime"
                                                        v-model="form.deliveryTime"
                                                        :label-prefix="labelPrefix"
                                        ></zw-input-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-select-group v-model="form.unit"
                                                         :options="getUnits() | optionsVL"
                                                         :label-prefix="labelPrefix"
                                                         name="unit"
                                        ></zw-select-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-input-group name="weight"
                                                        v-model="form.weigth"
                                                        :label-prefix="labelPrefix"
                                        ></zw-input-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-select-group v-model="form.weight_unit"
                                                         :options="getWeightUnits() | optionsVL"
                                                         :label-prefix="labelPrefix"
                                                         name="weight_unit"
                                        ></zw-select-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="3">
                                        <zw-new-date-group v-model="form.startDate"
                                                           name="start_date"
                                                           format="DD.MM.YYYY"
                                                           :label-prefix="labelPrefix"
                                        ></zw-new-date-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-new-date-group v-model="form.endDate"
                                                           name="end_date"
                                                           :label-prefix="labelPrefix"
                                                           format="DD.MM.YYYY"
                                        ></zw-new-date-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>

                        <custom-fields-block v-model="form.customValues"
                                             :fields="form.customFields"
                                             model="position"
                                             :form="form"
                        />

                    </b-col>
                    <b-col cols="4">
                        <b-row>
                            <b-col cols="12">
                                <b-card :header="$t('offering.position.group.price')">
                                    <b-card-text>
                                        <b-row>
                                            <b-col cols="6">
                                                <label class="mr-sm-2">{{ $t(labelPrefix + 'vat') }}</label>
                                                <zw-select-group v-model="form.vat"
                                                                 :options="getVats() | optionsVL"
                                                                 name="vat"
                                                                 disable-label
                                                                 :label-prefix="labelPrefix"
                                                                 @change="calcBruttoFromNetto"
                                                ></zw-select-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <zw-input-group name="count"
                                                                v-model="form.count"
                                                                type="number"
                                                                :label-prefix="labelPrefix"
                                                                @change="calcBruttoFromNetto"
                                                ></zw-input-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <zw-input-group name="price"
                                                                v-model="form.price"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                :label-prefix="labelPrefix"
                                                                @change="calcBruttoFromNetto"
                                                ></zw-input-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <zw-input-group name="brutto_price"
                                                                v-model="form.brutto_price"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                :label-prefix="labelPrefix"
                                                                @change="calcNettoFromBrutto"
                                                ></zw-input-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <zw-input-group name="EKprice"
                                                                v-model="form.EKprice"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                :label-prefix="labelPrefix"
                                                ></zw-input-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="6">
                                                <zw-input-group name="total_price"
                                                                v-model="form.total_price"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                :label-prefix="labelPrefix"
                                                                readonly
                                                ></zw-input-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <zw-input-group name="total_price_with_discount"
                                                                v-model="form.total_price_with_discount"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                :label-prefix="labelPrefix"
                                                ></zw-input-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                            <b-col cols="12">
                                <b-card :header="$t('offering.position.group.discount')">
                                    <b-card-text>
                                        <b-row>
                                            <b-col cols="12">
                                                <zw-input-group name="discount"
                                                                v-model="form.discount"
                                                                type="number"
                                                                min="0"
                                                                :label-prefix="labelPrefix"
                                                                @change="calcBruttoFromNetto()"
                                                ></zw-input-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <zw-select-group v-model="form.discountType"
                                                                 :options="discountTypeOptions()"
                                                                 name="discountType"
                                                                 :label-prefix="labelPrefix"
                                                                 @change="calcBruttoFromNetto()"
                                                ></zw-select-group>
                                            </b-col>
                                            <b-col cols="12">
                                                <zw-select-group v-model="form.discountTarget"
                                                                 :options="discountTargetOptions()"
                                                                 name="discountTarget"
                                                                 :label-prefix="labelPrefix"
                                                                 @change="calcBruttoFromNetto()"
                                                ></zw-select-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import CustomFieldsBlock from "@/components/CustomFieldsBlock";
import ZwNewDateGroup from "@/components/ZwNewDateGroup.vue";

export default {
    name: 'OfferingPositionModal',
    components: {ZwNewDateGroup, CustomFieldsBlock},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                discount: 0,
                discountType: 'prozent',
                startDate: null,
                endDate: null,
            },
            form: {},
            labelPrefix: 'columns.position.',
        }
    },
    methods: {
        ...mapGetters('Positions', ['getPosition']),
        ...mapGetters('CommonData', ['getVats', 'getDiscountTypes', 'getDiscountTargets', 'getUnits', 'getPositionTypes', 'getWeightUnits']),
        shown() {
            const position = this.$store.dispatch('Positions/fetchPosition', this.payload.id)

            Promise.all([position])
                .then(() => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getPosition()))
                    }
                    this.form.EKprice = this.form.EKprice || 0;
                    this.calcBruttoFromNetto();
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Positions/saveOfferingPosition', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        calcBruttoFromNetto() {
            let vat = parseFloat(this.form.vat)
            let netto = parseFloat(this.form.price)
            let brutto = (((vat + 100) / 100).toFixed(2) * netto).toFixed(2);

            this.form.brutto_price = brutto
            this.form = {
                ...JSON.parse(JSON.stringify(this.form)),
            }
            this.calcTotal();
        },
        calcNettoFromBrutto() {
            let vat = parseFloat(this.form.vat)
            if (vat > 0) {
                let brutto = parseFloat(this.form.brutto_price)
                let netto = (brutto / ((vat + 100) / 100).toFixed(2)).toFixed(2);

                this.form.price = netto
                this.form = {
                    ...JSON.parse(JSON.stringify(this.form)),
                }
            }
            this.calcTotal();
        },
        calcTotal() {
            let total_price = this.form.price * this.form.count;
            let total_price_with_discount = 0;
            if (this.form.discountTarget == 'einzeln') {
                if (this.form.discountType == 'fest') {
                    total_price_with_discount = (this.form.price - this.form.discount) * this.form.count
                }
                if (this.form.discountType == 'prozent') {
                    total_price_with_discount = (this.form.price - (this.form.price * (this.form.discount / 100))) * this.form.count
                }
            }
            if (this.form.discountTarget == 'gesamt') {
                if (this.form.discountType == 'fest') {
                    total_price_with_discount = this.form.price * this.form.count - this.form.discount
                }
                if (this.form.discountType == 'prozent') {
                    total_price_with_discount = this.form.price * this.form.count - this.form.price * (this.form.discount / 100)
                }
            }

            this.form.total_price = total_price;
            this.form.total_price_with_discount = total_price_with_discount;
        },
        openArticle(item_id) {
            this.$root.$children[0].openModal('article-modal', {id: item_id}, this.refreshTable)
        },
        discountTypeOptions: function () {
            let options = []
            this.getDiscountTypes().forEach(key => {
                options.push({
                    value: key,
                    text: this.$t('offering.position.discount.type.' + key),
                })
            })
            return options
        },
        discountTargetOptions: function () {
            let options = []
            this.getDiscountTargets().forEach(key => {
                options.push({
                    value: key,
                    text: this.$t('offering.position.discount.target.' + key),
                })
            })
            return options
        },
    },
}
</script>